<template>
  <!-- pc -->
  <div ref="scrollWrapperRef" @scroll="handleScroll">
    <div class="container-pc">
      <div class="list-box">
        <div class="list-view Global-List-W">
          <div :class="['list-left']">
            <div :class="[{ 'ISFISEDs': isFixed }, 'list-left-view']">
              <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item :title="$t(item.i18n)" :name="index" v-for="(item, index) in Propertys" :key="index">
                  <div class="list-left-item" v-for="(cell, cellindex) in item.list" :key="cellindex">
                    <!-- <el-checkbox-group fill="#414141"> -->
                    <el-checkbox v-model="cell.checked" @change="handleCheckBox(item.name, index, cell, item.sort)"
                      fill="#414141">
                    </el-checkbox>
                    <!-- </el-checkbox-group> -->
                    <div class="item-checkbox">
                      <div v-if="item.name == 'colorList'">
                        {{ cell.colorName }}
                      </div>
                      <div v-if="item.name == 'designList'">
                        {{ cell.designName }}
                      </div>
                      <div v-if="item.name == 'seriesList'">
                        {{ cell.seriesName }}
                      </div>
                      <div v-if="item.name == 'sizeList'">{{ cell.sizeName }}</div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
          <div class="list-right">
            <div class="list-right-view">
              <div :class="['list-item LISTITEM', { 'cate-skeleton': isShowSkeleton }]" v-for="(item, index) in proList"
                :key="index" @click="handleGopage(item)">
                <div class="list-item-hot" v-if="item.tagPcImage">
                  <img :src="item.tagPcImage" alt="">
                </div>
                <div class="list-item-img">
                  <img :src="item.imgs" alt="" class="IMGHOVER" />
                </div>
                <div class="list-item-xilie">{{ item.proSeries }}</div>
                <div class="list-item-name ELLIPSIS">{{ item.displayName }}</div>
                <div class="list-item-price">${{ item.salePrice }}</div>
              </div>
            </div>

            <!-- <div class="empty" v-if="isShowEmpty">
              暂无更多产品
            </div> -->

            <div class="LOADING_ROUND" v-if="isLoading"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- m -->
    <div class="container-m">
      <div :class="[{ 'ISFISEDVIEW': isFixed }, 'list-box-m-filter-view']" @click="handleShowDropDownFilter">
        <div :class="[{ 'ISFISED': isFixeds }, 'list-box-m-filter']">Filter & Sort </div>
      </div>
      <div class="list-box-m">


        <div class="list-view-m">
          <div :class="['list-item-m', { 'cate-skeleton': isShowSkeleton }]" v-for="(item, index) in proList"
            :key="index" @click="handleGopage(item)">
            <div class="list-item-hot-m" v-if="item.tagPcImage">
              <img :src="item.tagPcImage" alt="">
            </div>
            <div class="list-item-img-m">
              <img :src="item.imgs" alt="" />
            </div>
            <div class="list-item-xilie-m">{{ item.proSeries }}</div>
            <div class="list-item-name-m ELLIPSIS">{{ item.displayName }}</div>
            <div class="list-item-price-m">${{ item.salePrice }}</div>
          </div>
        </div>

        <div class="empty" v-if="isShowEmpty">
          <el-empty description="暂无更多产品" />
        </div>
        <div class="loading-view">
          <div class="LOADING_ROUND_M" v-if="isLoading"></div>
        </div>
      </div>

      <!-- popup -->
      <van-popup v-model:show="isShowFilter" position="left" :style="{ width: '80%', height: '100%' }">
        <div class="popup-view-m">
          <div class="popup-view-m-title">Filter & Sort</div>
          <van-collapse v-model="activeNamesM">
            <van-collapse-item :title="$t(item.i18n)" :name="index" v-for="(item, index) in Propertys" :key="index">
              <div class="popup-m-item" v-for="(cell, cellindex) in item.list" :key="cellindex">
                <van-checkbox v-model="cell.checked" @change="handleCheckBox(item.name, index, cell, item.sort)"
                  shape="square" checked-color="#414141" icon-size="15px">
                  <div class="item-checkbox-m">
                    <div v-if="item.name == 'colorList'">
                      {{ cell.colorName }}
                    </div>
                    <div v-if="item.name == 'designList'">
                      {{ cell.designName }}
                    </div>
                    <div v-if="item.name == 'seriesList'">
                      {{ cell.seriesName }}
                    </div>
                    <div v-if="item.name == 'sizeList'">{{ cell.sizeName }}</div>
                  </div>
                </van-checkbox>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </van-popup>
    </div>
  </div>
  <van-back-top bottom="150" />
</template>


<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "List",
  beforeRouteEnter(to, from, next) {
    // Do somethings
    if (from.name == 'Info') {
      to.meta.isBack = true
    }
    next()
  }
})
</script>

<script setup name="List">
import requestAsync from "@/utils/request";
import { onMounted, reactive, ref, watch, onBeforeUnmount, onActivated } from "vue";
import { useRouter, useRoute } from "vue-router";
import CountlyEvent from '../../utils/countlyEvent'
const countly = new CountlyEvent()

const route = useRoute();
const router = useRouter();

const activeNames = ref(["1"]);
const activeNamesM = ref(["1"]);

let proList = ref([]);
let Propertys = ref({});
let isShowFilter = ref(false);
let isShowSkeleton = ref(true);
let isShowEmpty = ref(false);
let menuId = ref("");
let isLoading = ref(false)
let isFixed = ref(false)
let isFixeds = ref(false)

const scrollWrapperRef = ref(null)
const scrollWrapperRefM = ref(null)

const queryParams = reactive({
  menuId: 0,
  pageNum: 1,
  sort: "",
  proSeries: "",
  proSize: "",
  proColor: "",
  proDesign: "",
});

const getProListPropertys = async () => {
  const data = await requestAsync({
    url: "/proTag/proListPropertys",
    data: {
      menuId: menuId.value,
    },
  });
  Propertys.value = handlePropertysName(data);
};

const getProList = async (type) => {
  if (isLoading.value) return
  isShowEmpty.value = false;
  isLoading.value = true
  queryParams.menuId = menuId;
  const data = await requestAsync({
    url: "/proTag/proList",
    data: queryParams,
  });
  if (type == 'Scroll') {
    proList.value = [...proList.value, ...data.products];
  } else {
    proList.value = data.products
  }
  if (data.products && data.products.length <= 0) {
    isShowEmpty.value = true;
  }
  isLoading.value = false
  /* 新增countly */
  const countlyData = {
    id: route.params.id,
    url: window.location.href
  }
  // console.log('countlyData', countlyData)
  countly.saveListId(countlyData)
  /* 新增countly */
  setTimeout(() => {
    isShowSkeleton.value = false;
  }, 100);
};

const handleToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}



/* 监听路由变化 */
watch(
  () => router.currentRoute.value.params.id,
  (newRouterName, oldRouterName) => {
    /* 
    oldRouterName
      1. 跳转其它页面

      2. 重新加载本页面
    
    */

    const oldProCode = window.localStorage.getItem('proCode')

    if (route.name != 'List') return
    window.localStorage.setItem('proCode', newRouterName)
    if (route.meta.isBack && oldProCode == newRouterName) return



    // if (oldRouterName && oldRouterName != newRouterName)
    menuId.value = route.params.id;
    queryParams.pageNum = 1
    getProListPropertys();
    getProList();
    handleToTop();
  },
  { immediate: true }
);

onMounted(() => {
  //   id = route.params.id;
  //   getProListPropertys(id);
  // console.log("onMounted");

  handleToTop()
  window.addEventListener('scroll', handleScroll, true);


});

onActivated(() => {
  // console.log('onActivated')
})






onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
})







/* 触底加载 */
const handleScroll = () => {
  if (isLoading.value) return
  if (isShowEmpty.value) return
  //获取视口高度
  // const windowHeight = window.screen.height;
  const container = scrollWrapperRef.value // 获取滚动容器
  if (container === null) return
  const { clientHeight } = container
  //获取页面滚动距离



  // 获取页面的总高度
  const totalHeight = document.body.scrollHeight;
  // 获取视口的高度
  const viewportHeight = window.innerHeight;
  // 返回滚动条距离底部的位置

  const scrollBottom = totalHeight - viewportHeight - window.scrollY


  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

  if (scrollTop > 50) {
    isFixed.value = true
  } else {
    isFixed.value = false
  }

  if (scrollBottom < 175) {
    queryParams.pageNum++
    getProList('Scroll');
  }
}

const handleScroll_M = () => {
  const container = scrollWrapperRefM.value // 获取滚动容器
  const { clientHeight } = container
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  if (clientHeight - 500 <= scrollTop && !isLoading.value) {
    console.log('加载中...')
    queryParams.pageNum++
    getProList();
  }
}

const handleChange = () => {
  alert;
};


const handleGopage = (info) => {
  router.push({
    name: "Info",
    params: {
      id: info.proCode,
    },
  });
};


const handleCheckBox = (type, index, info, sort) => {
  let list = Propertys.value[index];
  const selectList = list.list.filter((item) => item.checked);

  const selectString = [];
  selectList.forEach((element) => {
    selectString.push(element[sort]);
  });
  // console.log(selectString);
  // console.log(type);
  if (type == "seriesList") {
    queryParams.proSeries = selectString[0];
  }
  if (type == "designList") {
    queryParams.proDesign = selectString[0];
  }
  if (type == "sizeList") {
    queryParams.proSize = selectString[0];
  }
  if (type == "colorList") {
    queryParams.proColor = selectString[0];
  }
  // console.log(queryParams);
  queryParams.pageNum = 1
  getProList();
};

const handlePropertysName = (data) => {
  const p = [];
  for (let key in data) {
    p.push({
      name: key,
      list: data[key],
    });
  }

  p.forEach((item) => {
    switch (item.name) {
      case "colorList":
        item.sort = "colorName";
        item.sortName = "颜色";
        item.i18n = "List.Color";
        break;
      case "sizeList":
        item.sort = "sizeName";
        item.sortName = "尺寸";
        item.i18n = "List.Size";
        break;
      case "seriesList":
        item.sort = "seriesName";
        item.sortName = "系列";
        item.i18n = "List.Series";
        break;
      case "designList":
        item.sort = "designName";
        item.sortName = "形状";
        item.i18n = "List.Design";
        break;
    }
  });
  // console.log(p)
  return p;
};




/* 
    移动端
*/

const handleShowDropDownFilter = () => {
  isShowFilter.value = !isShowFilter.value;
};
</script>

<style lang="scss" scoped>
.list-box {
  background-color: #fff;
}

.list-view {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  min-height: 600px;
  padding: 30px 0;

  .list-left {
    position: relative;
    width: 250px;
    padding: 30px;
    background-color: #fff;

    .list-left-view {
      width: 200px;
      background-color: #fff;
    }

    .list-left-item {
      display: flex;
      align-items: center;
      height: 50px;

      .item-checkbox {
        margin-left: 20px;
      }
    }
  }

  .list-right {
    flex: 1 1;
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    padding: 0 30px;

    .list-right-view {}

    .list-item {
      position: relative;

      float: left;
      width: 252px;
      height: 385px;
      margin-bottom: 8px;
      margin-right: 8px;
      background-color: #f5f6f7;
      cursor: pointer;

      .list-item-hot {
        position: absolute;
        top: 10px;
        left: 10px;
      }

      .list-item-img {
        height: 252px;
        background-color: #f5f6f7;
        overflow: hidden;
      }

      .list-item-xilie,
      .list-item-price {
        height: 40px;
        line-height: 40px;
        text-align: center;
      }

      .list-item-name {
        height: 35px;
        padding: 0 10px;
        text-align: center;
      }
    }

    .list-item:hover {
      cursor: pointer;
    }

    .empty {
      width: 100%;
      text-align: center;
    }
  }
}


.list-box-m-filter-view {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.2rem 0;
  background-color: #fff;
}

.list-box-m-filter {
  width: 6.9rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  background-color: #fff;
  border: 1px solid #414141;
  border-radius: 5px;
  font-size: 0.25rem;
  font-weight: 700;
}

.list-box-m-filter:hover {
  background-color: #414141;
  color: #fff;
  cursor: pointer;
}


.list-box-m {
  width: 100%;
  padding: 0.25rem;
  background-color: #f5f6f7;


  .list-view-m {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.5rem 0;
    padding-top: 0;

    .list-item-m {
      position: relative;
      width: 49%;
      background-color: #fff;
      font-size: 0.2rem;
      padding-bottom: 0.2rem;
      margin-bottom: 0.2rem;

      .list-item-hot-m {
        position: absolute;
        top: 10px;
        left: 10px;
      }

      .list-item-img-m {
        min-height: 3.5rem;
        background-color: #fff;
      }

      .list-item-xilie-m,

      .list-item-price-m {
        height: 0.4rem;
        line-height: 0.4rem;
        text-align: center;
      }

      .list-item-name-m {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 0.8rem;

        text-align: center;
        padding: 0 .2rem;
      }
    }
  }
}

.popup-view-m {
  padding: 0.5rem 0;

  .popup-view-m-title {
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.3rem;
    font-weight: 700;
    padding-left: 0.25rem;
  }

  .popup-m-item {
    display: flex;
    align-items: center;
    height: 0.8rem;
    line-height: 0.8rem;

    .item-checkbox-m {
      margin-left: 0.15rem;
    }
  }
}

:deep() {
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #414141;
    border-color: #414141;
  }
}
</style>